const placeMarkers = async (locations, googleMaps, map) => {
  const geocoder = new googleMaps.Geocoder();
  const bounds = new googleMaps.LatLngBounds();
  const promises = [];
  locations.forEach((location) => {
    const {
      country, postalCode, riderId,
    } = location;
    const promise = new Promise((resolve, reject) => {
      const geocodeOptions = {
        componentRestrictions: {
          country,
          postalCode,
        },
      };
      const geocodeCallback = (results, status) => {
        if (status === 'OK') {
          const latlng = results[0].geometry.location;
          const marker = new googleMaps.Marker({
            position: latlng,
            riderId,
          });
          marker.setMap(map);
          bounds.extend(latlng);
          resolve(marker);
        } else { reject(status); }
      };
      geocoder.geocode(geocodeOptions, geocodeCallback);
    });
    promises.push(promise.catch((error) => error));
  });

  let markers = await Promise.all(promises);

  markers = markers.filter((marker) => (
    marker instanceof googleMaps.Marker
  ));

  if (!bounds.isEmpty()) {
    const c = bounds.getCenter();
    map.setCenter(bounds.getCenter(c));
    map.fitBounds(bounds);
    map.panToBounds(bounds);
  }
  return markers;
};

export default placeMarkers;
