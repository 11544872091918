import { Controller } from 'stimulus';
import loadGoogleMapsApi from 'load-google-maps-api';
import { PUBLIC_GOOGLE_MAPS_API_KEY } from '../constants';
import placeMarkers from '../map_helpers/place_location_markers';

class MapController extends Controller {
  static targets = ['map', 'locations'];

  new = async (rideShareId, passengersId) => {
    let url;
    if (rideShareId) {
      url = `ride_shares/${rideShareId}/rider_locations`;
    } else if (passengersId) {
      url = `/riders/${passengersId}/rider_location`;
    }
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': this.token,
      },
    });
    const data = await response.json();
    const googleMaps = await loadGoogleMapsApi({ key: PUBLIC_GOOGLE_MAPS_API_KEY });

    const map = new googleMaps.Map(this.mapTarget, {
      // Currently centered on london on initialization
      center: {
        lat: 51.5074,
        lng: 0.1278,
      },
      zoom: 12,
    });

    placeMarkers(data, googleMaps, map);
  }
}

export default MapController;
