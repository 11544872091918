import { Controller } from 'stimulus';

class ModalController extends Controller {
  open = () => {
    this.element.classList.add('is-active');
  }

  close = () => {
    this.element.classList.remove('is-active');
  }
}

export default ModalController;
