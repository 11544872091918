import { Controller } from 'stimulus';

class RideSharesController extends Controller {
  static targets = ['modal', 'notice', 'map', 'request', 'mapContainer'];

  token = null

  requestBtn = null

  profileId

  invitationType

  passengersId

  REQUEST_FAILURE = 'There appears to be something wrong. Please try again later.'

  REQUEST_SUCCESS = 'Successfully sent.'

  connect = () => {
    this.token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    this.requestBtn = document.querySelector('#request-join');
  }

  openRequest = (event) => {
    const { profileId, invitationType } = event.target.dataset;
    this.profileId = profileId;
    this.invitationType = invitationType;

    this.requestTargets.forEach((target) => {
      target.classList.remove('is-hidden');
    });

    this.mapTargets.forEach((target) => {
      target.classList.add('is-hidden');
    });

    this.#openModal();
  }

  sendRequest = (event) => {
    const message = document.querySelector('#message').value;

    fetch('/ride_share_invitations', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': this.token,
      },
      body: JSON.stringify({
        ride_share_invitation: {
          to: this.profileId,
          invitation_type: this.invitationType,
          message,
        },
      }),
    })
      .then((response) => {
        if (response.ok) {
          this.#openNoticeWithText(this.REQUEST_SUCCESS, 'success');
        } else {
          this.#openNoticeWithText(this.REQUEST_FAILURE, 'warning');
        }
        this.#closeModal();
      });
  }

  openLocations = (event) => {
    const { rideSharesId } = event.target.dataset;
    const { passengersId } = event.target.dataset;
    this.requestTargets.forEach((target) => {
      target.classList.add('is-hidden');
    });

    this.mapTargets.forEach((target) => {
      target.classList.remove('is-hidden');
    });

    this.#mapController().new(rideSharesId, passengersId);
    this.#openModal();
  }

  #closeModal = () => {
    this.#modalController().close();
  }

  #openModal = () => {
    this.#modalController().open();
  }

  #openNoticeWithText = (text, alert) => {
    this.#noticeController().open(text, alert);
  }

  #mapController = () => (
    this.application.getControllerForElementAndIdentifier(
      this.mapContainerTarget,
      'map',
    )
  );

  #noticeController = () => (
    this.application.getControllerForElementAndIdentifier(
      this.noticeTarget,
      'notice',
    )
  );

  #modalController = () => (
    this.application.getControllerForElementAndIdentifier(
      this.modalTarget,
      'modal',
    )
  );
}

export default RideSharesController;
