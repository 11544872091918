import { Controller } from 'stimulus';

class NoticeController extends Controller {
  static targets = ['output', 'alert']

  open = (text, alert) => {
    this.element.classList.remove('is-hidden');
    this.alertTarget.classList.add(`is-${alert}`);
    this.outputTarget.textContent = text;
  }

  close = () => {
    this.alertTarget.classList.remove('is-success');
    this.alertTarget.classList.remove('is-warning');
    this.element.classList.add('is-hidden');
  }
}

export default NoticeController;
